import { Controller } from "@hotwired/stimulus";
import Swiper, { Navigation, Pagination, Zoom } from "swiper";
// import Swiper and modules styles
import "swiper/swiper-bundle.css";
export default class extends Controller {
  connect() {
    var swiper = new Swiper(".mySwiper", {
      modules: [Navigation, Pagination, Zoom],
      zoom: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      effect: "fade",
      fadeEffect: {
        crossFade: true,
      },
    });
  }
}

$(document).ready(function () {
  $("#showPreviewSwipe").on("shown.bs.modal", function () {
    var swiper = new Swiper(".mySwiper", {
      modules: [Navigation, Pagination, Zoom],
      zoom: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    });
    swiper.update();
  });
});
