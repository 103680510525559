import Vuelidate from "vuelidate";
Vue.use(Vuelidate);
import { required, requiredIf, minLength, email } from "vuelidate/lib/validators";
import VueLocalStorage from "vue-localstorage";
Vue.use(VueLocalStorage);

document.addEventListener("DOMContentLoaded", function (_event) {
  $("body").on("click", "a.savoir-plus", function (event) {
    event.preventDefault();
    var target = $(this).data("target-id");
    $("div#template-" + target + " > div.truncated").hide();
    $("div#template-" + target + " > div.full").show();
  });

  $("body").on("click", "a.montrer-moins", function (event) {
    event.preventDefault();
    var target = $(this).data("target-id");
    $("div#template-" + target + " > div.truncated").show();
    $("div#template-" + target + " > div.full").hide();
  });

  if ($("body.kubes.new").length > 0) {

    function AppendToUrl(param, value) {
      var current_url = window.location.href;
      let url = new URL(current_url);
      let params = new URLSearchParams(url.search);

      if (params.has(param)) {
        params.set(param, value);
      } else {
        params.append(param, value);
      }

      window.history.pushState("forward", null, "?" + params.toString());
    }

    window.initPhoneFormater();

    let was_guest = window.location.search.includes("was_guest");
    //  no idea why it's here but I prefer keep it as is...we'll get rid of this soon hopefully
    //  but it's messing up with the querystring and the anonymous purchase after they sign up and come back here
    //  so the above variable was create before the mess is done
    if (window.history && window.history.pushState) {
      // var current_url = window.location.href;
      // let url = new URL(current_url);
      // let params = new URLSearchParams(url.search);
      // var kube_type = gon.kube.kube_type;
      // var kube_url_param = "";
      // if (kube_type == "kb") {
      //   kube_url_param = "originale1";
      // } else if (kube_type == "mj") {
      //   kube_url_param = "majuscule1";
      // }

      // params.append("kube", kube_url_param);
      // window.history.pushState("forward", null, kube_url_param);

      $(window).on("popstate", function () {
        window.order_app.goBack();
      });
    }

    window.order_app = new Vue({
      el: "#kube_order",
      data: {
        submitting: false,
        kube_name: null,
        base_url: "./#choisir&kube=",
        submitStatus: "",
        selected_item: null,
        template_id: null,
        templates_id: gon.templates_id,
        template: gon.template,
        coupon_id: gon.coupon_id,
        user: gon.current_user,
        kube: gon.kube,
        order: gon.order,
        current_page: "tastes",
        emailUnique: true,
        countryName: "France métropolitaine",
        bookSellerName: "",
        profile_features_count: gon.profile_features_count,
        send_chronicles: false,
        send_newsletters: false,
        birth_date_day: '',
        birth_date_month: '',
        birth_date_year: '',
        profile_features: {
          favorite_author: "",
          reading_per_year: "",
          favorite_authors: "",
        },
      },
      validations() {
        var profile_features_validations = {};
        if (this.profile_features_count == 0) {
          profile_features_validations = {
            reading_per_year: { required },
            favorite_author: {
              required,
            },
            favorite_authors: {
              required: requiredIf(function () {
                return this.profile_features.favorite_author == "true";
              }),
            },
          };
        }
        var wishes_obj = {};
        if (this.template_id == null) {
          wishes_obj = {
            prefered_categories: { required },
            wish_description: { required },
            already_read: { required },
            last_liked: { required },
            ideal_prefer: { required },
          };
        }
        var validations = {
          user: {
            first_name: {
              required,
            },
            last_name: {
              required,
            },
            email: {
              required,
              email,
              isUnique() {
                return this.emailUnique;
              },
            },
            password: {
              required,
              minLength: minLength(8),
            },
          },
          kube: {
            tentative_book_reviewed: { required },
            choose_bookseller: { required },
            ask_reading_request: {
              required: requiredIf(function () {
                return this.coupon_id == null;
              }),
            },
            preferred_bookseller_id: {
              required: requiredIf(function () {
                return this.kube.choose_bookseller == "true";
              }),
            },
            wishes: wishes_obj,
          },
          order: {
            first_name: {
              required,
            },
            last_name: {
              required,
            },
            street: {
              required,
            },
            zipcode: {
              required,
            },
            city: {
              required,
            },
            country_id: {
              required,
            },
            mobile_phone: {
              required,
            },
          },
          profile_features: profile_features_validations,
        };
        return validations;
      },
      localStorage: {
        kb: {
          type: Object,
        },
        kd: {
          type: Object,
        },
        mj: {
          type: Object,
        },
      },
      watch: {
        selected_item: function (new_item) {
          if (new_item == "questionaire") {
            this.template_id = null;
            this.template = {};
            this.kube.kube_template_id = null;
            $("#kube_template_id").val("");
            AppendToUrl("kube", this.kube_name + "2");
            // window.history.pushState("forward", null, this.base_url + this.kube_name + "2");
          } else {
            AppendToUrl("kube", this.kube_name + "template2");
            // window.history.pushState("forward", null, this.base_url + this.kube_name + "template2");
          }
        },
        "order.country_id": function (new_country_id) {
          this.countryName = $("#country option:selected").text();
          window.iti.setCountry($("#country option:selected").data("code"));
        },
        "profile_features.favorite_author": function (selection) {
          if (selection == "false") {
            this.profile_features.favorite_authors = "";
          }
        },
        current_page: function (new_page) {
          var kube_url_param = this.kube_name;
          if (new_page == "tastes") {
            kube_url_param += "2";
          } else if (new_page == "infos") {
            kube_url_param += "4";
          } else if (new_page == "account") {
            kube_url_param += "3";
          } else if (new_page == "recap") {
            kube_url_param += "5";
          }
          AppendToUrl("kube", kube_url_param);
        },
      },
      mounted() {
        this.kube_name = this.kube.kube_type == "kb" ? "originale" : "majuscule";
        this.countryName = $("#country option:selected").text();

        if (this.kube && this.kube.id != null && this.user.guest == false) {
          document.getElementById("stepInfo").scrollIntoView();
          //  It will jump to the info screen right away when:
          //  - anonymous coming back after signing up (regardless if questionnaire or template);
          //  - monthly choice and it's a kube template (the template must exists);
          if (was_guest || (this.kube.kube_template_id && this.templates_id.indexOf(this.kube.kube_template_id) > -1)) this.current_page = "infos";
          window.initPhoneFormater();
          this.$forceUpdate();
        }

        var ls_kube = this.$localStorage.get(this.kube.kube_type);

        if (ls_kube.kube_type == this.kube.kube_type && this.kube.id == null) {
          this.kube.ask_reading_request = ls_kube.ask_reading_request;
          this.kube.choose_bookseller = ls_kube.choose_bookseller;
          this.kube.tentative_book_reviewed = ls_kube.tentative_book_reviewed;
          this.kube.preferred_bookseller_id = ls_kube.preferred_bookseller_id;
          this.kube.wishes = ls_kube.wishes;
        }
        Vue.nextTick(function () {
          window.initPhoneFormater();
          $("input[name='order[mobile_phone][]']").on("countrychange", function () {
            window.order_app.$forceUpdate();
          });
        });
      },
      methods: {
        invalid_phone: function () {
          return !window.iti.isValidNumber();
        },
        select_template: function (template_id) {
          this.template_id = template_id;
          this.submitKube();
        },
        submitKube() {
          var that = this;
          this.submitting = true;
          $.ajax({
            type: "POST",
            url: "/kubes",
            data: {
              kube: that.kube,
              kube_template: that.selected_item,
              kube_template_id: that.template_id,
              profile_features: {
                favorite_author: that.profile_features.favorite_authors,
                reading_per_year: that.profile_features.reading_per_year,
              },
            },
            dataType: "json",
            success: function (data) {
              if (data) {
                that.bookSellerName = $("#kube_preferred_bookseller_id option:selected").text();
                that.kube = data.kube;
                that.order = data.order;
                that.template = data.template;
                that.$localStorage.set(that.kube.kube_type, that.kube);
                if (that.user.guest) {
                  document.getElementById("stepAccount").scrollIntoView();
                  that.current_page = "account";
                } else {
                  document.getElementById("stepInfo").scrollIntoView();
                  that.current_page = "infos";
                }
                Vue.nextTick(function () {
                  window.initPhoneFormater();
                  $("input[name='order[mobile_phone][]']").on("countrychange", function () {
                    window.order_app.$forceUpdate();
                  });
                });
                that.submitting = false;
              } else {
                console.log("Err");
                that.submitting = false;
              }
            },
            error: function (err) {
              console.log(err);
              that.submitting = false;
            },
          });
        },
        submitAccount() {
          this.$v.user.$touch();
          var that = this;
          this.submitting = true;
          if (this.$v.user.$invalid) {
            this.submitStatus = "ERROR";
            this.submitting = false;
          } else {
            this.submitStatus = "PENDING";
            $.getJSON(
              "/users/exist",
              {
                email: this.user.email,
              },
              function (data) {
                that.emailUnique = !data;
                that.$v.user.$touch();

                if (that.emailUnique) {
                  this.submitStatus = "OK";
                  $.ajax({
                    type: "PUT",
                    url: `/user`,
                    data: { user: that.user, user_send_chronicles: that.send_chronicles, user_send_newsletters: that.send_newsletters,
                            birth_date: `${that.birth_date_year}-${that.birth_date_month}-${that.birth_date_day}`
                          },
                  });
                  that.submitting = false;
                }
              }
            ).fail(function (data) {
              console.log(data);
              that.submitting = false;
            });
          }
        },
        showConfirm() {
          this.$v.order.$touch();
          if (!this.$v.order.$invalid) {
            if ($("#show-shipping-confirmation").val() == "true") {
              $("#shipping-info-confirm-container").modal("show");
            } else {
              document.getElementById("stepRecap").scrollIntoView();
              this.current_page = "recap";
              Vue.nextTick(function () {
                $("#order_mobile_phone_country_code").val(window.iti.getSelectedCountryData().dialCode);
              });
            }
          }
        },
        confirmInfos() {
          this.submitting = true;
          if ($("#confirm_check").is(":checked")) {
            $.post("/confirm-address-info").done(function (success) {
              if (success) {
                $("#show-shipping-confirmation").val(false);
              }
            });
          }
          document.getElementById("stepRecap").scrollIntoView();
          this.current_page = "recap";
          this.submitting = false;
        },
        submitOrder() {
          this.submitting = true;
          var that = this;
          var form = $("form.new_kube_form");
          var data = form.serialize();

          $.ajax({
            type: "PUT",
            url: `/kubes/${that.kube.id}`,
            data: data,
          }).done(function (success) {
            if (success) {
              window.location.href = success.redirect_url;
              that.submitting = false;
            }
          });
        },
        goBack: function () {
          switch (this.current_page) {
            case "recap":
              document.getElementById("stepInfo").scrollIntoView();
              this.current_page = "infos";
              break;
            case "infos":
              if (this.user.guest) {
                document.getElementById("stepAccount").scrollIntoView();
                this.current_page = "account";
              } else {
                document.getElementById("stepTastes").scrollIntoView();
                this.current_page = "tastes";
              }
              break;
            case "account":
              document.getElementById("stepTastes").scrollIntoView();
              this.current_page = "tastes";
              break;
          }
        },
      },
    });
  }
});
