import { Controller } from "@hotwired/stimulus"
import { Html5QrcodeScanner } from "html5-qrcode"
import { Html5QrcodeScanType } from "html5-qrcode"

var html5QrcodeScanner = null;

export default class extends Controller {

  static targets = [ 'scanbook', 'kubeId' ]

  initialize() {
    html5QrcodeScanner = null;
  }

  scanbookTargetConnected(element) {
    this.instantiate(element);
  }

  instantiate(element) {
    let config = {
      fps: 10,
      qrbox: 250,
      rememberLastUsedCamera: true,
      supportedScanTypes: [Html5QrcodeScanType.SCAN_TYPE_CAMERA]
    };

    html5QrcodeScanner = new Html5QrcodeScanner(element.id, config);
    html5QrcodeScanner.render(this.onScanSuccess);

  }

  onScanSuccess(decodedText, decodedResult) {
    let form = document.getElementById("frm_scanner");
    form.elements['term'].value = decodedText;
    form.requestSubmit();
    html5QrcodeScanner.clear();
  }

  scanner(element) {
    $('turbo-frame#modal')[0].src = `/scan-books?kube_id=${this.kubeIdTarget.value}`;
    element.preventDefault();
  }

  bookSelected(e) {
    window.dispatchEvent(
        new CustomEvent(
            "books:selected",
            {
              detail: {
                content: e.target.dataset.info
              }
            }
        )
    );
  }

  disconnect() {
    if (html5QrcodeScanner !== null) {
      html5QrcodeScanner.clear();
    }
  }
}
