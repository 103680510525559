document.addEventListener("DOMContentLoaded", function (_event) {
  if ($("#cancel_subscription").length > 0) {
    new Vue({
      el: "#cancel_subscription",
      data: {
        pause_options: false,
        phone_instructions: false,
        instructions: false,
        pause_months: "",
        pause_choices: "",
      },
      methods: {
        show_instructions: function () {
          this.instructions = true;
          this.pause_options = true;
        },
        cancel: function () {
          this.pause_options = false;
          this.phone_instructions = false;
          this.instructions = false;
          this.pause_months = "";
          this.pause_choices = "";
          $("#cancel_subscription div.radio").removeClass("active");
        },
        show_phone_instructions: function () {
          this.phone_instructions = true;
          this.pause_options = false;
          this.pause_months = "";
        },
      },
      mounted() {
        $(this.$refs.cancel_modal).on("hidden.bs.modal", this.cancel);
      },
    });
  }
});
