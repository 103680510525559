import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["planTotal", "interval", "planWelcome", "planTotalHtml", "country", "plan"];
  static values = {
    planTotal: String,
    planTotalHtml: String,
    planBase: Number,
    intervalQuantity: Number,
    shippingFee: Number
  };

  planTotalValueChanged(value, i) {
    let message = `${this.planTotalValue} tous les `;
    let parcel = "de la Lettre";
    let messageHtml = `${this.planTotalValue} <span>tous les `;

    if (this.intervalQuantityValue > 3) {
      parcel = "du Colis";
    }
    if (this.intervalQuantityValue < 12) {
      message += `${this.intervalQuantityValue} mois`;
      messageHtml += `${this.intervalQuantityValue} mois`;
    } else {
      message += 'ans';
      messageHtml += 'ans';
    }
    this.planTotalTargets.forEach((target) => {
      target.innerHTML = message;
    });

    this.planTotalHtmlTargets.forEach((target) => {
      target.innerHTML = messageHtml + '</span>';
    });

    this.planWelcomeTarget.innerHTML = parcel;
  }

  updateTotal() {
    this.planTotalValue = accounting.formatMoney((this.planBaseValue + (this.shippingFeeValue * (this.intervalQuantityValue / 3))) / 100);

    this.planTargets.forEach((target) => {
      let element = target.parentElement.parentElement.querySelector(".title .subtitle");
      element.innerHTML = `Soit ${((target.dataset.planBase / 100 + this.shippingFeeValue / 100 * (target.dataset.intervalQuantity / 3))) / (target.dataset.intervalQuantity / 3)} € / coffret`
    });
  }

  setValue(event) {
    $('.subscription-plan').removeClass('active');
    $(event.target).closest('.subscription-plan').addClass('active');
    this.intervalQuantityValue = event.target.dataset.intervalQuantity;
    this.planBaseValue = event.target.dataset.planBase;
    this.updateTotal();
  }

  setShippingFee(event) {
    this.shippingFeeValue = event.target.options[event.target.selectedIndex].dataset.shippingFee;
    this.updateTotal();
  }

  initialize() {
    window.temp = this;
    this.intervalQuantityValue = this.element.dataset.intervalQuantity;
    this.shippingFeeValue = this.countryTarget.options[this.countryTarget.selectedIndex].dataset.shippingFee;
    this.planBaseValue = this.element.dataset.planBase;
    this.updateTotal();
  }
}
