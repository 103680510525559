// from https://discuss.hotwired.dev/t/how-to-return-different-partial-with-hotwire-on-select-dropdwon-change/4571/6
//  This allow updating dynamically a form using POST/PATCH/PUT (submitting it)
//  If it's needed to update using GET, see form_controller
import { Controller } from "@hotwired/stimulus"

//  Accordingly to these verbs, we'll submit or just visit an URL
const SUBMIT_VERBS = ['POST', 'PATCH', 'PUT']

export default class extends Controller {
  static values = {
    url: String,
    name: String,
    verb: String
  }

  connect() {
    //  select2 is a jquery component that needs an additional listener
    if ($(this.element).data('select2')) {
      $(this.element).on('select2:select', function () {
        let event = new Event('change', { bubbles: true }) // fire a native event
        this.dispatchEvent(event);
      });
    }
  }

  update(event) {
    const url = new URL(this.urlValue);
    url.searchParams.append(this.nameValue, this.element.value);

    SUBMIT_VERBS.includes(this.verbValue?.toUpperCase()) ? event.target.closest('form').requestSubmit() : Turbo.visit(url);
  }
}