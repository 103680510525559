import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['durationInMonths', 'identifier', 'message', 'form', 'button']

  validateCoupon(e) {
    e.preventDefault();

    const regex = /^[a-zA-Z0-9_-]*$/;
    const coupon = this.identifierTarget.value;

    if (regex.test(coupon)) {
     this.buttonTarget.disabled = true;
     this.formTarget.submit();
    }
    else {
      this.messageTarget.classList.remove("hide");
      this.messageTarget.innerHTML = 'Un coupon ne peut contenir que des caractères alphanumériques en plus de - et _';

      setTimeout(() => {
        this.hideErrorMessage();
      }, 5 * 1000);
    }
  }

  hideErrorMessage() {
    this.messageTarget.classList.add('hide');
  }

  setDurationInMonths(e) {
    this.durationInMonthsTarget.value = e.target.options[e.target.selectedIndex].dataset.duration;
    if (e.target.options[e.target.selectedIndex].dataset.code == 'nh')
      this.durationInMonthsTarget.setAttribute('readonly', true);
    else
      this.durationInMonthsTarget.removeAttribute('readonly');
  }
}
