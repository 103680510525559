/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import { Turbo } from "@hotwired/turbo-rails";
Turbo.session.drive = false;

import "controllers";

import { Application } from "stimulus";
import ContentLoader from "stimulus-content-loader";
import Reveal from "stimulus-reveal-controller";
import CharacterCounter from "stimulus-character-counter";

import wNumb from "wnumb";
window.wNumb = wNumb;

import * as noUiSlider from "nouislider";
import "nouislider/dist/nouislider.css";

window.noUiSlider = noUiSlider;

const application = Application.start();
// Stimulus.debug = true
application.register("content-loader", ContentLoader);
application.register("reveal", Reveal);
application.register("character-counter", CharacterCounter);

import Vuelidate from "vuelidate";
Vue.use(Vuelidate);

require("../kube_order");
require("../cancel_modal");
require("../kids");
require("../textarea");
