import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  static values = {
    kubeType: String
  }

  connect() {
    $('.application-clean .questionnaire-select').select2({
      tags: true,
      width: '100%',
    });

    $('.application-clean .form-change-trigger').click(e => {
      e.preventDefault();
      let wrapper = $(e.target).closest('.questionnaire-wrapper');
      wrapper.removeClass('with-account without-account');
      wrapper.addClass(e.target.dataset.change);
      $('.footer-clean input[type="submit"]').attr('form', e.target.dataset.form);
    })
  }
}
