import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'kubeId',
    'icon',
    'info',
    'title',
    'isbn',
    'publisher',
    'price',
    'term',
    'submitter'
  ]

  connect() {
    // Settings object that controls default parameters for library methods:
    accounting.settings = {
      currency: {
        symbol: "€", // default currency symbol is '$'
        format: "%v %s", // controls output: %s = symbol, %v = value/number (can be object: see below)
        decimal: ",", // decimal point separator
        thousand: ".", // thousands separator
        precision: 2 // decimal places
      },
      number: {
        precision: 2, // default precision on numbers is 0
        thousand: ".",
        decimal: ","
      }
    };
  }

  //  Handles the end of the modal submition
  //  After booksellers/kubes_controller.rb#book_assignment()
  submitEnd(e) {
    e.detail.fetchResponse.response.text().then(value => {
      if (value.includes('Livre bien ajouté')){
        //  If it cames from admin and is editing, just refresh the page without editing the book
        if (window.location.pathname.includes('admin')) {
          window.location = window.location.href.split('?')[0];
        } else {
          window.location = '/booksellers?page=' + $("#page").val();
        }
      }
    })
  }

  //  Handles the end of the manual book creation
  //  After booksellers/kubes_controller.rb#book()
  manualBookSubmitEnd(e) {
    e.detail.fetchResponse.response.text().then(value => {
      let message = JSON.parse(value);
      if (message.result == 'Livre bien ajouté') {
        e = {
          target: {
            dataset: {
              info: message.book
            }
          }
        }
        this.bookSelected(e);
      }
    })
  }

  loaded(e) {
    if (this.hasIconTarget) {
      this.iconTarget.classList.remove('fa-spinner')
      this.iconTarget.classList.remove('fa-spin')
      this.iconTarget.classList.add('fa-search')
    }
  }

  reload(e) {
    let term = (this.hasTermTarget ? this.termTarget.value : '');
    $('turbo-frame#modal')[0].src = `/find-books?kube_id=${this.kubeIdTarget.value}&term=${term}`;
    $('turbo-frame#modal')[0].reload();
    //  prevent to keep scroll as is
    e.preventDefault();
  }

  search(e) {
    if (e.type == 'click' || (e.type == 'keydown' && e.which == 13)) {
      //  prevent to keep scroll as is
      e.preventDefault();

      this.validateTerm(e)
    }
  }

  validateForm(e) {
    this.submitterTarget.disabled = true;
    const minLength = 50;
    const errorMessage = "Ton message est trop court 😢 Enrichis-le pour donner envie à ton Kuber de se plonger sans tarder dans le livre que tu lui recommandes !";

    if ($.trim($('#bookseller_custom_phrase').val()).length < minLength) {
      e.preventDefault();
      e.detail.formSubmission.stop();
      alert(errorMessage);
    } else if (this.isbnTarget.value == '') {
      e.preventDefault();
      e.detail.formSubmission.stop();
      alert("L'ISBN est vide. Rechercher un livre");
    }
  }

  reenable(e) {
    this.submitterTarget.disabled = false;
  }

  validateTerm(e) {
    if (this.termTarget.value.length >= 3) {
      //  find.html uses <form> and _show.html uses <a>
      //  they should be handled differently
      if (e.type != 'turbo:submit-start') {
        this.reload();
      }
      if (this.hasIconTarget) {
        this.iconTarget.classList.remove('fa-search')
        this.iconTarget.classList.add('fa-spinner')
        this.iconTarget.classList.add('fa-spin')
      }
    } else if (e.type == 'turbo:submit-start') {
      e.preventDefault();
    }
  }

  //  click->booksellers--kubes#bookSelected
  bookSelected(e) {
    window.dispatchEvent(
      new CustomEvent(
        "books:selected",
        {
          detail: {
            content: e.target.dataset.info
          }
        }
      )
    );
  }

  useBook({ detail: { content } }) {
    let book = JSON.parse(window.b64DecodeUnicode(content));
    this.titleTarget.textContent = book.title;
    this.isbnTarget.value = book.isbn;
    this.publisherTarget.textContent = (book.publisher ? book.publisher : "Pas d'éditeur");
    this.priceTarget.textContent = accounting.formatMoney(book.price/100);
  }
}
