import { Controller } from '@hotwired/stimulus'

export default class extends Controller
{
  static targets = [
    "address",
    "city",
    "phone",
    "postalCode",
    "country"
  ];

  initialize() {
    this.placeChanged = this.placeChanged.bind(this);
  }

  connect() {
    if (typeof google !== "undefined") {
      this.initAutocomplete();
    }

    window.initPhoneFormater();

    if (this.countryTarget) {
      this.restrictCountry();
      $(this.countryTarget).on('select2:select', function () {
        let event = new Event('change', { bubbles: true }) // fire a native event
        this.dispatchEvent(event);
      });
    }
  }

  initAutocomplete() {
    this.autocomplete = new google.maps.places.Autocomplete(this.addressTarget, this.autocompleteOptions);
    this.autocomplete.addListener('place_changed', this.placeChanged.bind(this));
  }

  placeChanged() {
    this.place = this.autocomplete.getPlace();
    const addressComponents = this.place.address_components;

    if (addressComponents !== void 0) {
      const formattedAddress = this.formatAddressComponents(addressComponents);
      this.setAddressComponents(formattedAddress);
    }
  }

  setAddressComponents (address) {
    if (this.hasCityTarget) this.cityTarget.value = address.locality || "";
    if (this.hasPostalCodeTarget) this.postalCodeTarget.value = address.postal_code || "";
    if (this.hasAddressTarget) {
      let full_address = this.addressTarget.value.split(',');

      if (full_address.length >= 3) {
        full_address.splice(-2);
      }
      else if (full_address.length === 2) {
        if (isNaN(full_address[1])) {
          full_address.splice(-1);
        }
      }

      this.addressTarget.value = full_address;
    }
  }

  get autocompleteOptions() {
    return {
      fields: ['address_components']
    }
  }

  preventSubmit(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }

  formatAddressComponents(addressComponents) {
    const data = {};

    addressComponents.forEach((component) => {
      const type = component.types[0];
      data[type] = component.long_name;
    });

    return data;
  }

  restrictCountry() {
    let country_code = this.countryTarget.selectedOptions[0].dataset.code;

    if (country_code !== undefined) {
      this.restrictCountryGoogle(country_code);
      window.iti.setCountry(country_code);
    }
  }

  restrictCountryGoogle(countrycode) {
    this.autocomplete.setComponentRestrictions({country: countrycode});
  }

  cleanupForm() {
    if (this.hasCityTarget) this.cityTarget.value = "";
    if (this.hasPhoneTarget) this.phoneTarget.value = "";
    if (this.hasPostalCodeTarget) this.postalCodeTarget.value = "";
    if (this.hasAddressTarget) {
      this.addressTarget.value = "";
      this.addressTarget.focus();
    }
  }
}

// export { GooglePlacesAutocomplete as default };

window.initTest = function () {
  // API library requires callback function to exist.
}


window.initAutocomplete = function(...args) {
  const event = document.createEvent("Events")
  event.initEvent("google-maps-callback", true, true)
  event.args = args
  window.dispatchEvent(event)
}
