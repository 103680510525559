import { Controller } from "@hotwired/stimulus";
import debounce from "debounce";

export default class extends Controller {

  static targets = ["increase", "removeDiscount", "discount", "form"];

  initialize() {
    this.submit = debounce(this.submit.bind(this), 200);
  }

  removeDiscount(event) {
    event.preventDefault();
    this.discountTarget.value = "";
    this.removeDiscountTarget.value = "true";
    this.formTarget.requestSubmit();
  }

  removeIncrease(event) {
    event.preventDefault();
    this.increaseTarget.value = 0;
    this.formTarget.requestSubmit();
  }

  submit(event) {
    var radio = event.target.querySelector('input[type="checkbox"]');
    if(radio.checked) {
      this.increaseTarget.value = radio.value;
    } else {
      this.increaseTarget.value = 0;
    }
    this.formTarget.requestSubmit();
  }
}
