// from https://eagerworks.com/blog/nested-select-fields-hotwire-stimulus
//  This allow updating dynamically a form using GET
//  If it's needed to submit a form, see input_submit_controller
import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr";

export default class extends Controller {
  static targets = ["form", "input"];

  inputTargetConnected(element) {
    if (element.classList.contains('flatpicker_date')) {
      if (!element._flatpickr) {
        flatpickr(".flatpicker_date", {
          enableTime: false,
          locale: "fr",
          dateFormat: "d/m/Y",
        });
      }
      element._flatpickr.config.onChange.push(function() { console.log('triger');});
      element._flatpickr.config.onChange.push(this.fetchForm.bind(this));
    }
    else
    element.addEventListener("change", this.fetchForm.bind(this));
  }

  async fetchForm() {
    const response = await fetch(this.urlWithQueryString(), {
      headers: {
        'Accept': 'text/vnd.turbo-stream.html',
      }
    });
    const html = await response.text();
    Turbo.renderStreamMessage(html);
  }

  urlWithQueryString() {
    return `${this.url()}?${this.queryString()}`;
  }

  url() {
    return window.location.href;
  }

  queryString() {
    const form = new FormData(this.formTarget);
    const params = new URLSearchParams();
    for (const [name, value] of form.entries()) {
      params.append(name, value);
    }

    return params.toString();
  }
}
