import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["coupon", "couponCode"];
  static values = {
    plan: Number
  };

  setPlan(event) {
    $('.subscription-plan').removeClass('active');
    $(event.target).closest('.subscription-plan').addClass('active');
    let radio = $(event.target).closest('.subscription-plan').find('.switcher .coupon')[0];
    this.planValue = radio.dataset.planId;
    let device = radio.dataset.device;
    this.couponTargets.forEach((coupon) => {
      //  previous plan selected
      if (!coupon.classList.contains('hidden')) {
        coupon.classList.toggle('hidden');
      }
      //  new plan selected
      if (coupon.dataset.planId == radio.dataset.planId) {
        coupon.classList.toggle('hidden');
      }
    });
    this.selectCoupon(this.planValue, device);

    this.couponCodeTarget.value = `coupon_${radio.dataset.code}`;
  }

  selectCoupon(planId, device, quantity = null) {
    this.couponTargets.forEach((coupon) => {
      //  based on the device since the page has desktop and mobile versions that are rendered at the same time
      if (planId == coupon.dataset.planId && coupon.dataset.couponIdx.includes(device)) {
        //  must trigger click due to Vue
        //  select the first of them of the plan if none is specified
        if (!quantity && coupon.querySelector('.switcher .coupon').dataset.index == 0)
          coupon.querySelector('.switcher .coupon').click();
        else if (quantity && coupon.querySelector('.switcher .coupon').dataset.quantity == quantity)
          coupon.querySelector('.switcher .coupon').click();
      }
    });
  }

  setCoupon(event) {
    let div = $(event.target).closest('.radio');
    div.find(`#${div[0].dataset.couponIdx}`).click();
  }

  connect() {
    let self = this;
    // due to https://github.com/hotwired/stimulus/issues/328
    setTimeout(function() {
      let device = $(".coupons-new-mobile").is(":visible") ? "mobile" : "desktop";
      let planId = self.element.dataset.planId;
      const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      });
      let quantity = params.duration;

      $(`.bandes-dessinees .coupons-new-${device} input[name='bandes_dessinees_coupon[plan_id]'][value='${planId}']`).parent().parent().children()[1].click();
      self.selectCoupon(planId, device, quantity);
    }, 100);
  }
}
