//  https://www.bearer.com/blog/how-to-build-modals-with-hotwire-turbo-frames-stimulusjs
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["modal", 'modalContent', 'flash']

  // hide modal
  // action: "modal#hideModal"
  hideModal() {
    this.modalTarget.parentElement.removeAttribute("src")
    // Remove src reference from parent frame element
    // Without this, turbo won't re-open the modal on subsequent click
    this.modalTarget.remove()
  }

  // hide modal on successful form submission
  // action: "turbo:submit-end->modal#submitEnd"
  submitEnd(e) {
    if (e.detail.success) {
      //  Let the caller decides if want to hide it or not
      //  To do so, just call: data-action: "modal:submitEnd@window->modal#hideModal"
      //  Otherwise: data-action: "modal:submitEnd@window->doSomethingElse"
      //  borrowed from https://www.refactoredtelegram.net/2021/03/communication-among-stimulus-controllers-part-2/
      window.dispatchEvent(
        new CustomEvent(
          "modal:submitEnd",
          {
            detail: e.detail
          }
        )
      );
    }
  }

  // action: "turbo:load->modal#lframeLoadEndoaded"
  frameLoadEnd(e) {
    if (e.target.id == 'modal') {
      window.dispatchEvent(
        new CustomEvent(
          "modal:loaded",
          {
            detail: e.detail
          }
        )
      );
    }
  }

  // hide modal when clicking ESC
  // action: "keyup@window->modal#closeWithKeyboard"
  closeWithKeyboard(e) {
    if (e.code == "Escape") {
      this.hideModal()
    }
  }

  // hide modal when clicking outside of modal
  // action: "click@window->modal#closeBackground"
  closeBackground(e) {
    if (e && (this.modalTarget.contains(e.target) || (this.hasFlashTarget && this.flashTarget.contains(e.target)))) {
      return;
    }
    this.hideModal()
  }

  connect(){
    $(this.modalContentTarget)
      .draggable({handle: '.modal-header'})
      .resizable({handles: "e, w", minWidth: 200});
    $(this.element).modal();
    document.documentElement.addEventListener("turbo:frame-load", this.frameLoadEnd);
  }

  disconnect() {
    $(this.element).modal('hide');
    document.documentElement.removeEventListener("turbo:frame-load", this.frameLoadEnd);
  }
}
