import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["hiddenSubmit", 'kubeSubType'];

  setSubType(event) {
    var element = $(event.target).closest('.subscription-plan')[0];
    this.kubeSubTypeTarget.value = element.dataset.planSubType;
  }

  submit() {
    this.hiddenSubmitTarget.click();
  }
}
