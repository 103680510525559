import Vuelidate from "vuelidate";
Vue.use(Vuelidate);
import { required, requiredIf, minLength, email } from "vuelidate/lib/validators";
import VueLocalStorage from "vue-localstorage";
Vue.use(VueLocalStorage);

document.addEventListener("DOMContentLoaded", function (_event) {
  // Settings object that controls default parameters for library methods:
  accounting.settings = {
    currency: {
      symbol: "€", // default currency symbol is '$'
      format: "%v %s", // controls output: %s = symbol, %v = value/number (can be object: see below)
      decimal: ",", // decimal point separator
      thousand: ".", // thousands separator
      precision: 2, // decimal places
    },
    number: {
      precision: 2, // default precision on numbers is 0
      thousand: ".",
      decimal: ",",
    },
  };

  // script first_name /kids_subscriptions/new
  if ($("body.kids_subscriptions.new").length > 0) {
    var app = new Vue({
      el: "#app",
      mounted: function () {
        $("input[name=coupon_name]").first().trigger("click").trigger("change");
      },
      data: {
        current_user: gon.current_user,
        countries: gon.countries,
        selected_country: gon.current_user.country_id != null ? gon.current_user.country_id : gon.countries[0].id,
        base_price: gon.base_price,
        months: 1,
      },
      computed: {
        shipping_cost: function () {
          var countries = this.countries;
          var selected_country = this.selected_country;

          var country = $.grep(countries, function (v) {
            return v.id == selected_country;
          });

          if (country.length > 0) {
            return country[0].shipping_cost_cents / 100;
          } else {
            return null;
          }
        },
        total: function () {
          return (this.base_price / 100 + this.shipping_cost) * this.months;
        },
      },
      methods: {
        formatMoney: function (value) {
          return accounting.formatMoney(value);
        },
      },
    });
  }

  // script for /nouvelle-commande?plan_id=3
  if ($("#kids-app").length > 0) {

    window.kids_app = new Vue({
      el: "#kids-app",
      data: {
        current_page: "tastes",
        currentStep: 1,
        validated: 1,
        user: gon.current_user,
        kube: gon.kube,
        google_ads_id: 0,
        note_text: "",
        coupon_kids_qty: 1,
        order: gon.order,
        emailUnique: true,
        countryName: "France métropolitaine",
      },
      mounted() {
        this.countryName = $("#country option:selected").text();
        if (this.kube && this.kube.id != null && this.user.guest == false) {
          this.current_page = "infos";
          window.iti.setNumber($("input[name='order[mobile_phone][]']").val());
        }
        var ls_kube = this.$localStorage.get(this.kube.kube_type);
        if (ls_kube.kube_type == this.kube.kube_type && this.kube.id == null) {
          this.kube.ask_reading_request = ls_kube.ask_reading_request;
          this.kube.choose_bookseller = ls_kube.choose_bookseller;
          this.kube.tentative_book_reviewed = ls_kube.tentative_book_reviewed;
          this.kube.preferred_bookseller_id = ls_kube.preferred_bookseller_id;
          this.kube.wishes = ls_kube.wishes;
        }
        Vue.nextTick(function () {
          window.initPhoneFormater();
          $("input[name='order[mobile_phone][]']").on("countrychange", function () {
            window.kids_app.$forceUpdate();
          });
        });
      },
      watch: {
        "order.country_id": function (new_country_id) {
          this.countryName = $("#country option:selected").text();
          window.iti.setCountry($("#country option:selected").data("code"));
        },
      },
      computed: {
        bookSellerName() {
          return $("#kube_preferred_bookseller_id option:selected").text();
        },
      },
      validations() {
        var validations = {
          user: {
            first_name: {
              required,
            },
            last_name: {
              required,
            },
            email: {
              required,
              email,
              isUnique() {
                return this.emailUnique;
              },
            },
            password: {
              required,
              minLength: minLength(8),
            },
          },
          kube: {
            tentative_book_reviewed: { required },
            choose_bookseller: { required },
            ask_reading_request: {
              required: requiredIf(function () {
                return this.coupon_kids_qty > 1;
              }),
            },
            preferred_bookseller_id: {
              required: requiredIf(function () {
                return this.kube.choose_bookseller == "true";
              }),
            },
            wishes: {
              wish_description: { required },
              reading_mood: { required },
              prefered_categories: { required },
              has_images: { required },
              already_read: { required },
              last_liked: { required },
              observations: { required },
            },
          },
          order: {
            first_name: {
              required,
            },
            last_name: {
              required,
            },
            street: {
              required,
            },
            zipcode: {
              required,
            },
            city: {
              required,
            },
            country_id: {
              required,
            },
            mobile_phone: {
              required,
            },
          },
        };
        return validations;
      },
      methods: {
        invalid_phone: function () {
          return !window.iti.isValidNumber();
        },
        submitKube() {
          var that = this;
          $.ajax({
            type: "POST",
            url: "/kubes",
            data: {
              kube: that.kube,
            },
            dataType: "json",
            success: function (data) {
              if (data) {
                that.kube = data.kube;
                that.order = data.order;
                that.$localStorage.set(that.kube.kube_type, that.kube);
                that.current_page = that.user.guest ? "account" : "infos";
                $("html, body").animate({ scrollTop: 0 }, "slow");
              } else {
                console.log("Err");
              }
            },
            error: function (err) {
              console.log(err);
            },
          });
        },
        submitAccount() {
          this.$v.user.$touch();
          var that = this;
          if (this.$v.user.$invalid) {
            this.submitStatus = "ERROR";
          } else {
            this.submitStatus = "PENDING";
            $.getJSON(
              "/users/exist",
              {
                email: this.user.email,
              },
              function (data) {
                that.emailUnique = !data;
                that.$v.user.$touch();

                if (that.emailUnique) {
                  this.submitStatus = "OK";
                  $.ajax({
                    type: "PUT",
                    url: `/user`,
                    data: { user: that.user },
                  });
                }
              }
            ).fail(function (data) {
              console.log(data);
            });
          }
        },
        submitOrder() {
          var that = this;
          var form = $("form.new_kube_kids_form");
          var data = form.serialize();

          $.ajax({
            type: "PUT",
            url: `/kubes/${that.kube.id}`,
            data: data,
          }).done(function (success) {
            if (success) {
              window.location.href = success.redirect_url;
            }
          });
        },
        showConfirm() {
          if (!this.$v.order.$invalid && !this.invalid_phone()) {
            if ($("#show-shipping-confirmation").val() == "true") {
              $("#shipping-info-confirm-container").modal("show");
            } else {
              this.current_page = "recap";
              Vue.nextTick(function () {
                $("#order_mobile_phone_country_code").val(window.iti.getSelectedCountryData().dialCode);
              });
            }
          }
        },
        confirmInfos() {
          if ($("#confirm_check").is(":checked")) {
            $.post("/confirm-address-info").done(function (success) {
              if (success) {
                $("#show-shipping-confirmation").val(false);
              }
            });
          }
          this.current_page = "recap";
        },
        change_country: function () {
          this.user.country_name = $("#country option:selected").text();
        },
        goBack: function () {
          switch (this.current_page) {
            case "recap":
              this.current_page = "infos";
              break;
            case "infos":
              if (this.user.guest) {
                this.current_page = "account";
              } else {
                this.current_page = "tastes";
              }
              break;
            case "account":
              this.current_page = "tastes";
              break;
          }
        },
      },
    });
  }
});
