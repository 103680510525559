import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "templateForm", "isbn"]

  addBook(event){
    event.preventDefault()

    const element = this.isbnTarget
    const isbn = element.value

    fetch(`/admin/books/find_create_by_isbn?isbn=${isbn}`)
    .then(function(response) {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response;
    })
    .then(response => response.text())
    .then((data) =>  {
      // this.bookValue = data
      this.bookFormTarget.innerHTML = data;
      this.templateFormTarget.hidden = false
    })
    .catch(function() {
      this.templateFormTarget.hidden = true
      console.log("error");
    })
  }

  connect() {
    // this.templateFormTarget.hidden = false
  }
}
