import { Controller } from "@hotwired/stimulus"


export default class extends Controller {
  static targets = ["details", "form", "actionButton"]

  connect() {
    this.actionButtonTarget.value = '';
  }

  showDetails(event) {
    // Verifica se o evento foi disparado por um input radio ou label
    const target = event.target.tagName === 'INPUT' ? event.target : event.target.querySelector('input[type="radio"]');
    if (target && target.type === 'radio') {
      this.hideAllDetails()
      const selectedDetails = target.closest('label').nextElementSibling
      if (selectedDetails && selectedDetails.classList.contains('details')) {
        selectedDetails.classList.remove('hidden')
        selectedDetails.classList.add('show')
      }
    }
  }

  hideAllDetails() {
    this.detailsTargets.forEach(details => {
      details.classList.remove('show')
      details.classList.add('hidden')
    })
  }

  submit(event) {
    event.preventDefault();
    const buttonValue = event.currentTarget.dataset.value;

    this.actionButtonTarget.value = buttonValue;

    this.formTarget.requestSubmit();
  }
}
