import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["continuer", "regular", "trial", "option", "form", "RegularCouponError", "trialSelect", "regularSelect"];

  select(e) {
    e.preventDefault();
    this.continuerTarget.classList.add("hidden");
    this.optionTarget.value = e.target.dataset.option;
    if (this.hasTrialSelectTarget) {
      this.trialSelectTarget.selectedIndex = 0;
    }

    if (this.hasRegularSelectTarget) {
      this.regularSelectTarget.selectedIndex = 0;
    }

    if (e.target.dataset.option == "regular") {
      this.toggle_visible("regular");
    } else if (e.target.dataset.option == "trial") {
      this.toggle_visible("trial");
    } else if (e.target.dataset.option == "new") {
      this.formTarget.requestSubmit();
    }
  }

  regular_change(e) {
    var selected_option = e.currentTarget.selectedOptions[0];

    this.continuerTarget.classList.add("hidden");
    this.RegularCouponErrorTarget.classList.add("hidden");
    if (selected_option.dataset.discounts == "true") {
      this.RegularCouponErrorTarget.classList.remove("hidden");
    } else {
      this.continuerTarget.classList.remove("hidden");
    }
  }

  trial_change(e) {
    var selected_option = e.currentTarget.selectedOptions[0];

    this.continuerTarget.classList.add("hidden");

    if (selected_option.value != "") {
      this.continuerTarget.classList.remove("hidden");
    }
  }

  toggle_visible(target) {
    if (target == "regular") {
      this.regularTarget.classList.remove("hidden");
      if (this.hasTrialTarget) {
        this.trialTarget.classList.add("hidden");
      }
    } else if (target == "trial") {
      this.trialTarget.classList.remove("hidden");
      if (this.hasRegularTarget) {
        this.regularTarget.classList.add("hidden");
      }
    }
  }

  connect() {}
}
