import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['otherReason', 'submit'];

  choose(event) {
    let value = event.target.value;

    this.otherReasonTarget.style.display = (value == 'other' ? '' : 'none');
    this.otherReasonTarget.required = value == 'other';

    this.submitTarget.style.display = '';
  }
}
